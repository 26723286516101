// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-how-we-work-js": () => import("./../../../src/templates/how-we-work.js" /* webpackChunkName: "component---src-templates-how-we-work-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/what-we-do.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */),
  "component---src-templates-who-we-are-js": () => import("./../../../src/templates/who-we-are.js" /* webpackChunkName: "component---src-templates-who-we-are-js" */)
}

